import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PrivacyCommitment from '../components/sections/privacy';
import FrequentlyAskedQuestions from '../components/sections/privacy/frequently-asked-questions';
import SEO from '../components/seo';
import PrivacyIndustryHero from '../components/sections/privacy/privacy-industry-hero';
import AccordionPrivacyFAQ from '../components/sections/privacy/accordion-privacy-faq';
import IndustryPrivacySelector from '../components/sections/privacy/industry-privacy-selector';
import PrivacyButtons from '../components/sections/privacy/privacy-buttons';
import './privacy-industry.css';

const PrivacyNightclubsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      privacyHero: file(relativePath: { eq: "hero-images/privacy-hero.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privacyFeature: file(relativePath: { eq: "hero-images/privacy_feature.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const accordionQuestions = [
    {
      title: 'What is Patronscan?',
      response: `<p>Patronscan is an identification (ID) scanner that uses Optical Character Recognition (OCR) and barcode scanner technology to authenticate and verify over 4,500 types of government IDs for the hospitality industry. The Patronscan system verifies a patrons age, confirms ID expiration date and catches fake IDs by using proprietary ID authentication technology. Patronscan is also used as an informational system that allows venues to flag individuals who are reported to have engaged in conduct on a patron’s presence that created a serious risk to guests or staff.</p>
      <br>
      <p> Since 2005, Patronscan has equipped multiple businesses in the hospitality industry with the technology to protect their guests, staff and property.Patronscan is currently working in over 600 venues spanning 200 cities worldwide and has scanned over 100, 000, 000 IDs.</p>
      <p>Patronscan has been proven as a safety tool that increases safety for the guests and staff of venues. An independent study conducted by the state of New South Wales in Australia collected data from before and after Patronscan ID scanners were used in Kings Cross, an inner-city locality, and found that on-premise alcohol-related assaults were reduced by 50% and theft by 85% during specified hours.&nbsp;<sup>1</sup></p>
      <p>&nbsp;</p>
      <p><strong><sup>1</sup></strong><strong>&nbsp;The study specified that,&nbsp;The New South Wales Bureau of Crime Statistics and Research data shows on-premises alcohol-related non-domestic assaults (from 9pm to 1:30am) in Kings Cross high risk venues fell by 50% when comparing the period before scanners were introduced (July 2012 – June 2014) to the period following their introduction (July 2014 – June 2016). Steal from person (bag snatches) in high risk venues (from 9pm to 1:30am) declined by 85.4% over the same period.&nbsp;<br>Source:&nbsp;<a href="https://www.liquorandgaming.nsw.gov.au/documents/reports/kings-cross-id-scanner-review-report-sept-2016.pdf">www.liquorandgaming.nsw.gov.au</a></strong></p>`,
    },
    {
      title: `How does Patronscan protect the public?`,
      response: `A top reason why businesses use Patronscan is to protect their guests, staff and property. The Patronscan system offers technological tools to mitigate security and liability risks, including underage drinking, property damage, violence, and other behaviors that endanger public safety. Patronscan also allows for businesses to track patron numbers and determine capacity, understand patron demographics and alert management of patrons who have exhibited a history of physical and sexual assault, or other threatening behaviors. IDs are scanned for several reasons. First, the scan verifies age, which is important for entering licensed premises and using age-restricted products. Second, the scan authenticates and verifies the validity of an ID, and preventing patrons from using a fake ID to enter the venue. Third, the technology serves as a crime prevention tool by comparing patrons against a confidential list of flagged patrons who have an established history of threatening behavior. At times, the mere presence of a Patronscan kiosk at an establishments entrance can deter unwanted patrons from seeking admission. Patronscan is a useful investigative tool that assists local law enforcement to identify a patron who has allegedly engaged in criminal activity at a venue.`,
    },
    {
      title: 'How does Patronscan flag bad behavior?',
      response: `
      <p>Venues with Patronscan notice a decrease in incidents by becoming aware of the patrons known to cause these risks, keeping their guests, staff and venues safe. This is because the flagged list allows venues to track and identify known violators before they enter their venue.
      Venues who serve alcohol are at a greater risk of violent incidents due to patrons inhibitions being lowered. According to studies, some people are more likely to be aggressive after drinking alcohol, putting themselves, other guests and venue staff at risk. In a community-based study, it was found that 42 percent of violent crimes reported to the police involved alcohol, and 51 percent of the victims interviewed believed that their assailants had been drinking.
      Patronscans own data indicate that 95% of violent incidents at venues are caused by less than 1 percent of patrons, meaning that most incidents are repeated by the same patrons. Patronscan is a technology tool that enables
      owners and staff to make an informed decision on whether to allow or deny entry to patrons known to endanger safety.</p>
      <br>
<p><strong><sup>2</sup></strong><strong>&nbsp;<a href="https://www.abc.net.au/health/thepulse/stories/2014/01/30/3934877.htm">https://www.abc.net.au/</a>&nbsp;<br><sup>3</sup>&nbsp;<a href="https://pubs.niaaa.nih.gov/publications/aa38.htm">https://pubs.niaaa.nih.gov/</a></strong></p>`,
    },
    {
      title: 'What behaviors are flagged?',
      response: `
      <p>For most jurisdictions, behaviors that may result in placing a patron on the flagged list include:</p>
      <p>&nbsp;</p>
      <div style="padding-left: 40px;">- Violence</div>
      <div style="padding-left: 40px;">- Assault</div>
      <div style="padding-left: 40px;">- Destruction of Property</div>
      <div style="padding-left: 40px;">- Sexual Assault</div>
      <div style="padding-left: 40px;">- Fraud</div>
      <div style="padding-left: 40px;">- Theft</div>
      <p style="padding-left: 40px;">&nbsp;</p>
      <p>As of Jan 1, 2019, in California, behaviors that result in data being on the flag network are limited to Fraud, Abuse and Material Misrepresentation as per CA Civ. Code 1798.90.1.</p>`,
    },
    {
      title: 'What is the difference between a network vs. venue flag?',
      response: `
      <p><span>Flags can be either single venue or shared across all networked venues (shared with other participating venues). Venue flags pertain only to the venue/venues in the same ownership group who placed the flag and are not visible to any other venue. As of January 1st, 2019, the maximum flag period for any existing or new venue flag is 5 years. This storage period applies only for the same business flags. If you have a venue flag, it will only be displayed if your ID is scanned at the business that created the flag, if you visit a different business your flag will not be displayed.</span></p>
      <br>
      <p><span>Networked flags can be viewed by other venues on the network. These flags are only shown when a patrons ID is scanned at the venue. Venues cannot search or view other business’ flags. Flags are only visible when the patrons ID is scanned at those venues. The maximum flag period for a networked flag is 1 year in length.</span></p>
      <br>
      <p><span>A Patronscan flag is an alert only and does not provide visibility into patron history.</span></p>
      <br>
      <p><span>As of 2019, 76% of all flags on the flag list were venue flags, in comparison to 24% of flags that were networked. Networked flags are often placed on patrons who commit the most egregious incidents, such as violent and sexual assaults. Flagging these patrons helps to improve safety across the network, preventing harmful behaviors from being committed again at a different venue. Venues and areas with security tools like Patronscan notice a decrease in violence and incidents. Patronscan is a recognized safety tool by law enforcement, the Responsible Hospitality Institute, and by city, county and government officials.</span></p>`,
    },
    {
      title: 'What data is collected?',
      response: `
      <p><span>Patronscan collects limited data in order to, verify and authenticate patron age and match patrons against the venue or networked flag list.</span></p>
      <p><span>Patronscans collection of data is limited to:</span></p>
      <p>&nbsp;</p>
      <div style="padding-left: 40px;">- Name</div>
      <div style="padding-left: 40px;">- Date of Birth</div>
      <div style="padding-left: 40px;">- Photo</div>
      <div style="padding-left: 40px;">- Gender</div>
      <div style="padding-left: 40px;">- Postal Code/Zip Code</div>
      <p>&nbsp;</p>

      <p><span>Patronscan limits the collection of information to only what we consider important to verify age, to avoid an inaccurate match to the known list, and for law enforcement investigations when a crime is committed.</span></p>
      <p>For the province of Alberta, only name, age and photograph are collected in accordance with legislation under the GLCA.</p>`,
    },
    {
      title: 'What data is stored and for how long?',
      response: `
      <p><span>Unless a patron is flagged, data is retained for a limited period of time before being permanently deleted. This period allows crime victims sufficient time to report a crime and for law enforcement to review patron records to identify the alleged assailant(s). It is common for victims to report crimes several days to weeks later.
      Data is permanently deleted as per the data retention period below:</span></p>
      <p>&nbsp;</p>
      <div style="padding-left: 40px;"><span>- 90 days in the United States (as of January 1st, 2019*)</span></div>
      <div style="padding-left: 40px;"><span>- 21 days in most Canadian provinces (excluding British Columbia)</span></div>
      <div style="padding-left: 40px;"><span>- 24 hrs. in British Columbia</span></div>
      <div style="padding-left: 40px;"><span>- 90 days in Australia and New Zealand</span></div>
      <div style="padding-left: 40px;"><span>- 90 days in the United Kingdom</span></div>
      <p>&nbsp;</p>
<p><span>The only data that is saved beyond the above time frames is specific to patrons that are on the flag list.</span></p>`,
    },
    {
      title: 'Who has access to data?',
      response: `
      <p><span>The venue owner and management staff have limited access to this data for a short period of time. If the venue needs to log an incident and place a patron on the flagged list for example, the venue can reference a photo and data such as name, age, and gender. For an added layer of protection, Patronscan has enabled different user types, with limited access. Only users with administrative access can view patron personal data (usually management and/or security).</span></p>
        <br>
<p><span>Patronscan combines all non-personally identifiable data points such as postal/zip codes, age and gender to create summarized totals reports. This information is cross-referenced with publicly available census data. The summarized totals reports contain aggregate data such as scan counts for the night and never contain any personal information about any specific individuals.</span></p>
        <br>
<p><span>In case of a major incident concerning public safety, law enforcement may obtain access to a venues data, but only when an official investigation has been launched. The three conditions in which law enforcement may request Patronscan information include:</span></p>
<p><span>&nbsp;</span></p>

<div style ="padding-left: 40px;"><span>1. The law enforcement agency has identified its lawful authority to obtain the information.</span></div>
<div style ="padding-left: 40px;"><span>2. The law enforcement agency has indicated that the disclosure is requested for the purpose of enforcing a law in its jurisdiction, carrying out an investigation relating to the enforcement of any such law, or gathering intelligence for the purpose of enforcing any such law.</span></div>
<div style ="padding-left: 40px;"><span>3. The law enforcement agency has provided an investigation number or any other uniquely identifiable number that can be traced back to the purpose of the disclosure request.</span></div>
</div>`,
    },
    {
      title: 'Is data given away or sold?',
      response: `
      <p>No personal data is provided to third parties outside of law enforcement and venue staff. Again, unless a patron is flagged, data is permanently deleted shortly after visiting an establishment.</p>`,
    },
    {
      title: 'Can patrons request copies of their personal data?',
      response: `
      <p><span>Patrons have the right to request what private information has been collected, used and/or disclosed by clicking the “I want to dispute a flag” or “I want to check my info” button below the last question on left side of this FAQ and by filling out a disclosure request form. Once Patronscan has received the completed form, a response will be provided within 10 business days.</span></p>`,
    },
    {
      title: 'How is data protected?',
      response: `
      <p>Patronscan uses a variety of security technologies and procedures to help protect patron personal data from unauthorized access, use or disclosure. Patronscan stores all personal data on computer servers with access controls and that are located in controlled facilities. When, transmitting sensitive data over the internet, Patronscan protects it through the use of encryption software such as software adhering to the Secure Socket Layer (SSL) protocol. Patronscan also encrypts all data stored on its database server.</p>
<p>Patronscan only uses certified data centers to store all data collected. The data centers are SSAE16 SOC 2 certified, security reviewed facilities with existing infrastructure of industry standard server and security technology. Procedures are in place to restrict logical access to this data center and client systems.</p>`,
    },
    {
      title: 'How can one be removed from the flagged list?',
      response: `
      <p><span>The first step in removing yourself from the flagged list is to contact the venue that added you to the list. If there is a dispute regarding the flag, the patron may contact the manager/head of security of the venue that created the flag as they are the only ones that have the access to modify/remove the flag.</span></p>
<p><span>If the flag is not resolved by the venue within 14 days, or a Patron did not engage in the behavior that resulted in the flag, then the Patron may request a formal investigation by Patronscan. Please click “</span><a href="https://dispute.patronscan.com/investigation-request/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable"><span>Start a Formal Investigation</span></a>”<span>&nbsp;to start a formal investigation. Once Patronscan receives the completed form, we will respond with a disclosed result of the investigation within 10 business days.&nbsp;</span></p>`,
    },
    {
      title: 'How does Patronscan prohibit discrimination?',
      response: `
      <p>Under federal anti-discrimination laws, businesses can refuse service to any person for any reason, unless the business is discriminating against a protected class including; race or color, national origin or citizenship status, religion or creed, sex, age, disability, pregnancy or genetic information and veteran status. In states such as California, the list of protected classes includes; marital status, sexual orientation or gender identity, medical condition or HIV/AIDS status, military or veteran status, political affiliation or activities, status as a victim of domestic violence, and assault or stalking.</p>
<p>Patronscan prohibits the use of the Patronscan system to create flags based on discrimination of protected classes. If a patron has been flagged and believes the flag was placed based on discrimination of the above protected classes, the patron may start a formal investigation with Patronscan.&nbsp;<a href="https://dispute.patronscan.com/investigation-request/" rel="noopener" target="_blank">Click Here to Start a Formal Investigation</a>. Once we receive the completed form, we will respond with disclosed results of its investigation within 10 business days. Patronscan does not tolerate flags for any unlawfully discriminatory purpose of this nature and is committed to resolving flags based on discrimination quickly. If any venue is found to be using the Patronscan system for a discrimination purpose, Patronscan will immediately suspend all services to that venue.</p>`,
    },
    {
      title: 'Is Patronscan privacy compliant in every jurisdiction?',
      response: `
      <p>We believe that it is fully compliant. In all consultations and investigations, the Patronscan system has been found to comply with all privacy laws in their respective jurisdictions. Patronscan limits the collection of personal information, uses of that information, and offers Patrons the ability to challenge flags that they believe are inappropriate.</p>`,
    },
    {
      title: 'What happens when privacy laws change?',
      response: `
      <p><span>Patronscan endeavors to make all required changes in its practices to meet the letter and spirit of changes in privacy laws that affect its service. For example, we revamped our service in order to comply with California Assembly Bill 2769. Patronscan went above and beyond the law by making procedures by which Patrons may challenge flags significantly easier to use (something that this law does not require).</span></p>
<p><span>Patronscan continues to work in parallel with governments to comply with all changes to privacy laws.</span></p>`,
    },
    {
      title: 'Is Patronscan privacy compliant in Canada?',
      response: `
      The Patronscan system is compliant with all Canadian privacy laws. The technology itself has undergone investigations by the Office of the Information and Privacy Commissioner of Canada, the Office of the Information and Privacy Commissioner of Alberta and the Office of the Information and Privacy Commissioner of B.C. Patronscan has been found to be compliant with PIPA and PIPEDA. If your ID is scanned in Canada, your data is stored in Canada in compliance with all Canadian privacy laws.`,
    },
    {
      title: 'Is Patronscan privacy compliant in California?',
      response: `<p>In order to be in full compliance with the State of California amendment of Section 1798.90.01 of the civil code relating to privacy (amendment bill AB-2769), Patronscan has made modifications to the software, which apply to the scanning of driver’s licenses or government issued identification cards.</p>
      <br>
      <p>CA Civ. Code 1798.90.1 has been in effect since 2004, limiting to only “swiping” a driver’s license for certain activities. AB 2769, which went into effect January 1, 2019, now includes “scanning” a driver’s license, subject to the same limitations.</p>
      <br>
      <p>The law specifically permits Patronscan’s customers to use Patronscan kiosks to prevent underage drinking and the use of fake identification. Additionally, collecting and disclosing an individual’s personal information is clearly permitted by the statute to report, investigate, or prevent “abuse, fraud and material misrepresentation”.</p>
      <br>
      <p>On January 1st, 2019 the following changes were made to the Patronscan system:</p>
      <br>
      <p><strong>Public Safety Reports:&nbsp;</strong>Reports that detail aggregate non-personal information regarding trends and threat assessments for specific municipalities are no longer available within the Patronscan system. All previous reports have been deleted.&nbsp;<br><strong>Recording Bad Behavior<sup>*</sup>:&nbsp;</strong>Patronscan now limits recorded categories of bad behavior to violence, sexual assault and destruction of property.&nbsp;<br><br><sup>*</sup>Effective January 1, 2019, the categories of bad behaviors recorded (or flagged) by Patronscan users are limited to the behaviors that could pose a direct threat to safety. “Generic” descriptions are no longer be allowed to be based upon an open-ended rationale for a patron to be denied access to an establishment. Patronscan’s new policy in compliance with the law is to scanning of a patron’s identification only for the purpose of preventing “Abuse, Fraud, or Material Misrepresentation”.&nbsp;<br><br><strong>Simple/Transparent Appeal Process:&nbsp;</strong>New policies that make it easier for patrons to dispute being flagged for bad behavior.&nbsp;<br><br><strong>Dispute Resolution:&nbsp;</strong>Patronscan now has 10 business days to respond to disputed flags. This is a decrease from previous policy to allow for quicker dispute resolution.&nbsp;<br><br><strong>Limited Disclosure of Personal Data:&nbsp;</strong>The amount of personal information visible to the Patronscan kiosk operator about a flagged patron is reduced, with no date of birth displayed.&nbsp;<br><br><strong>Flag Underage Drinkers:&nbsp;</strong>The Patronscan system continue to identify fake IDs.&nbsp;<br><br><strong>Limited Collection of Personal Data:&nbsp;</strong>Patronscan limits the data collected to name, photo, gender, date of birth and Zip code (address/driver’s license number are not collected or permitted)</p>
      <p>&nbsp;</p>`,
    },
    {
      title: 'Questions and Complaints',
      response: `<p>If you have any questions or concerns about any collection, use or disclosure of personal information by Patronscan, or about a request for access to your own personal information, please contact our privacy officer:</p>
      <blockquote>
        <br>
        <p>Privacy Officer</p>
        <p>1-877-778-9798 ext. 4</p>
        <p><a class="text-blue" href="mailto:privacy@patronscan.com">privacy@patronscan.com </a><br></p>
      </blockquote>
        <br>
        <p>In making a complaint to us about privacy, please give us enough details to be able to identify your concerns and respond appropriately. You must provide us with your name and contact details and a description of your complaint. We will respond to you in a reasonable time frame (not more than 30 calendar days).</p>`,
    }
  ];

  return (
    <div>
      <SEO title="Our Commitment to Privacy - Patronscan" />
      <PrivacyIndustryHero
        heroImage={data.privacyHero.childImageSharp.fluid}
        heading="Privacy Policy"
        subHeading="Entertainment and Retail"
      />
      <h2 className="text-3xl font-hairline font-heading text-grey-dark mb-3 privacy-heading">
        {' '}
        Your Privacy
      </h2>
      <p className="privacy-text">
        Patronscan is committed to protecting people and their privacy. It is our responsibility to
        protect all patron personal information. We comply with all privacy regulations in their
        respective jurisdictions. To learn more about Patronscan's privacy committment, click on the
        questions below.
      </p>
      <div className="privacy-accordion">
        <h2 className="text-3xl font-hairline font-heading text-grey-dark mb-3 privacy-accordion-header">
          Frequently Asked Questions
        </h2>
        <AccordionPrivacyFAQ questions={accordionQuestions} />
      </div>
      <PrivacyButtons />
      <IndustryPrivacySelector />
    </div>
  );
};

export default PrivacyNightclubsPage;
